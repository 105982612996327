/** Here to Work: Richard big with shovel **/

import React from "react";
import { preloadImages, preloadAudio, showText, scaleFullscreen, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Linear } from "gsap";
import { navigate } from 'gatsby';

let globalState, data, preloadData, steamTL1, steamTL2;;

class HereToWorkPage3 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[3];
        preloadData = globalState.assets[4];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        console.log('page 5 mounted');

        //position text
        positionText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);
        

        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        
        //add base track
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //mine
        
        //remove prev track
        //globalState.baseAudioRef.updateTrack(2, false);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Animate Steam
        setTimeout(()=>{
            steamTL1 = gsap.timeline({ repeat: -1 });
            steamTL2 = gsap.timeline({ repeat: -1});
            gsap.set(this.refs.steam1Ref, {alpha: 0, scale: 1});
            gsap.set(this.refs.steam2Ref, {alpha: 0.5, scale: 1.05});
            
            steamTL1.to(this.refs.steam1Ref, 3.33, { alpha: 1, scale: 1.09, ease: Linear.easeNone });
            steamTL1.to(this.refs.steam1Ref, 3.33, { alpha: 0, scale: 1.2, ease: Linear.easeNone });
            
            steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 1, scale: 1.09, ease: Linear.easeNone });
            steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0.5, scale: 1.15, ease: Linear.easeNone });
            steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0, scale: 1.2, ease: Linear.easeNone });
            steamTL2.to(this.refs.steam2Ref, 0, { alpha: 0, scale: 1 });
            steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0.5, scale: 1.05, ease: Linear.easeNone });
            

          }, globalState.transitionDuration);
        
        
    

    


        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.diggingSteam,
            globalState.cdnUrl + preloadData.digger1,
            globalState.cdnUrl + preloadData.diggerSprite,
            globalState.cdnUrl + preloadData.diggerStand,
            globalState.cdnUrl + preloadData.diggingPile,
            globalState.cdnUrl + preloadData.diggingTrain,
            globalState.cdnUrl + preloadData.diggingTrainpile,
            globalState.cdnUrl + preloadData.diggingRock,
            globalState.cdnUrl + preloadData.diggingLine,
            globalState.cdnUrl + preloadData.diggingOverlay
        ], this.preloadImgCallback);

    }

    preloadImgCallback = () => {
        //this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
        const preloadData = globalState.assets[4];
        preloadAudio([
            globalState.cdnUrl + preloadData.shovelSound1, 
            globalState.cdnUrl + preloadData.shovelSound2,
            globalState.cdnUrl + preloadData.shovelSound3,
            globalState.cdnUrl + preloadData.shovelSound4,
            globalState.cdnUrl + preloadData.steamSound,
        ], this.preloadAudioCallback);

    }
    preloadAudioCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        gsap.killTweensOf(this.refs.text2Ref);

        gsap.killTweensOf(this.refs.steamWrapperRef);
        gsap.killTweensOf(steamTL1);
        gsap.killTweensOf(steamTL2);

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);
            
        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");
        }
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 725
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1287, 725, true);
        
    }

    isTransitioning = () => {

        //pause animations
    }
    handleNextClick = () => {
        console.log('Next clicked');
        this.refs.prevNextRef.updateNextHijacking(false);
        navigate("/here-to-work/4");
      }


    render() {

        return (

            <>

                <link rel="prefetch" href="/here-to-work/4" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/here-to-work/4" 
                    nextHijack={true} nextCallback={this.handleNextClick}
                    prevLocation="/here-to-work/2"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Here To Work" />

                    <div className="fullscreen-bg digging-wrapper" ref="fullscreenBgRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background digger-bg" />

                        <div ref="steamWrapperRef" className="steamWrapper">
                            <img src={globalState.cdnUrl + data.diggingSteam} alt="Digging Steam" className="steam" ref="steam1Ref" />
                            <img src={globalState.cdnUrl + data.diggingSteam} alt="Digging Steam" className="steam" ref="steam2Ref" style={{opacity: "0.5", marginLeft: "59px", marginTop: "37px"}} />
                        </div>

                        <img src={globalState.cdnUrl + data.richardCloseup} alt="Richard" className="background" />
                    </div>

                    <div className="text-wrapper">
                        <div className="text text--right-bottom" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "4vw", marginBottom: "100px" }} onClick={this.toggleTextDisplay} alt="From the moment an event occurs, it is simplified and purified in memory. We shave off the rough edges and what happened becomes a story or even, over time, a legend. If we’re not careful, though, we grind it down to raw superlatives, with none of the banalities or complications that make truth feel true. So often a memory depends on who we need to be at the moment of remembrance. Sometimes it’s better to believe that we accomplished the impossible." />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default HereToWorkPage3
